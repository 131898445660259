import React from 'react';
import { LatLngExpression } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { Devices } from '../Typings/deviceTypes';
interface DeviceMarkerProps {
  device: Devices;
}

const DeviceMarker: React.FC<DeviceMarkerProps> = ({ device }) => {
  if (device.location === false) {
    return null;
  }

  const coords: LatLngExpression = [device.location.lat, device.location.lng];
  const usrName = device.alias || 'Guest';

  return (
    <Marker key={device._id} position={coords}>
      <Popup>
        Username: {usrName}
        <br />
        Last Updated: {new Date(device.location.reported).toString()}
      </Popup>
    </Marker>
  );
};

export default React.memo(DeviceMarker, (prevProps, nextProps) => {
  return prevProps.device._id === nextProps.device._id &&
         prevProps.device.location.lat === nextProps.device.location.lat &&
         prevProps.device.location.lng === nextProps.device.location.lng &&
         prevProps.device.alias === nextProps.device.alias &&
         prevProps.device.location.reported === nextProps.device.location.reported;
});