import axios, { AxiosResponse } from 'axios';
import { AccessControls } from '../../Typings/adminTypes';
import {
  UserRequest, MobileActivation, User, UserRequestChannel,
} from '../../Typings/userTypes';
import { ToastType } from '../../Typings/toastTypes';
import { Channel } from '../../Typings/channelTypes';
import type SocketController from '../../Utils/SocketController';
import userLogic, { UserChannelRes } from './usersLogic';
import { ADMIN, SENDER, SENDER_ACCESS_CONTROL } from '../Admin/adminLogic';
import DatabaseHandler from '../../Firebase/databaseHandler';

const validateData = (
  username: string, email: string, password: string, setErrorState: (msg: string) => void, roleState: { name: string }, edit: boolean,
): boolean => {
  const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!username.length) {
    setErrorState('Please enter a username');
    return false;
  }
  if (username.includes('-')) {
    setErrorState('\'-\' character not permitted in username');
    return false;
  }
  if (email.length) {
    if (!regEx.test(email)) {
      setErrorState('Please enter a valid email');
      return false;
    }
  }
  if (!edit || password.length) {
    if (password.length < 8) {
      setErrorState('Please enter a password of at least 8 characters.');
      return false;
    }
  }

  if (roleState.name === 'Select A Role' || !roleState || !roleState.name) {
    setErrorState('Please select a role');
    return false;
  }
  return true;
};

const filterAccessControls = (accessControls: AccessControls[], roleName: string): string[] => {
  if (roleName === ADMIN) { // "Admin" role always gets all access controls
    return accessControls.map((a) => a._id);
  }
  if (roleName === SENDER) { // "Sender" always only gets the "geo_send_alert" access control
    return accessControls.filter((a) => a.name === SENDER_ACCESS_CONTROL).map((a) => a._id);
  } // "Mobile" never gets any access controls
  return [];
};

const submitAddUser = async ({
  e,
  setErrorState,
  username,
  email,
  password,
  roleState,
  channelState,
  channels,
  accessControls,
  mobileActivation,
  dispatch,
  toggle,
  socket,
}: {
  e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  setErrorState: (msg: string) => void,
  username: string,
  email: string,
  password: string,
  roleState: { id: string, name: string },
  channelState: UserRequestChannel[],
  channels: Channel[],
  accessControls: AccessControls[],
  mobileActivation: MobileActivation,
  dispatch: (action: { type: string, payload: User[] | ToastType | User }) => void,
  toggle: () => void,
  //socket: SocketController,
}): Promise<boolean> => {
  if (e !== undefined) e.preventDefault();
  setErrorState('');
  const isValid = validateData(username, email, password, setErrorState, roleState, false);
  if (!isValid) {
    return false;
  }
  console.log('CHANNELSTATE', channelState);

  // TODO add channels to user
  const addUserData = {
    username,
    email,
    password,
    //admin: roleState.name === ADMIN || roleState.name === SENDER,
    role: roleState.name,
    //channels: channelState,
    //accessControls: filterAccessControls(accessControls, roleState.name),
    //mobileActivation,
  };

  console.log('ADDUSERDATA', addUserData);
  await DatabaseHandler.userCrud('POST', addUserData);
  // TODO get response and dispatch a notification
  toggle();
  return true;
};

const arrayofAccessControls = (
  i: { _id: string },
  accessControlState: string[],
  setAccessControlState: (mes: string[]) => void,
): boolean => {
  if (accessControlState.includes(i._id)) {
    setAccessControlState(accessControlState.filter((acc: string) => acc !== i._id));
    return true;
  }
  setAccessControlState([...accessControlState, i._id]);
  return false;
};

const submitEditUser = async ({
  e,
  id,
  channelState,
  channels,
  roleState,
  username,
  password,
  email,
  mobileActivation,
  accessControls,
  toggle,
  dispatch,
  setErrorState,
  socket,
}: {
  e: React.MouseEvent<HTMLButtonElement> | undefined,
  id: string,
  channelState: UserRequestChannel[],
  channels: Channel[],
  accessControls: AccessControls[],
  roleState: { id: string, name: string },
  username: string,
  password: string,
  email: string,
  mobileActivation: MobileActivation,
  toggle: () => void,
  dispatch: (action: { type: string, payload: ToastType | User[] }) => void,
  setErrorState: (msg: string) => void,
  //socket: SocketController,
}): Promise<boolean> => {
  if (e !== undefined) e.preventDefault();

  const isValid = validateData(username, email, password, setErrorState, roleState, true);
  if (!isValid) {
    return false;
  }

  const data: UserRequest = {
    //admin: roleState.name === ADMIN || roleState.name === SENDER,
    id,
    username,
    role: roleState.name,
    accessControls: filterAccessControls(accessControls, roleState.name),
    mobileActivation,
  };
  console.log('EDITCHANNELS', channelState);
  if (channelState) {
    const channelArray = [];
    for (const channel of channelState) {
      const chan = {
        id: channel.channel,
        admin: channel.admin,
      }
        channelArray.push(chan);
    }
    // @ts-ignore
    data.channels = channelArray;
  }

  //if (channelState) data.channels = channelState;
  if (password.length) data.password = password;
  if (email.length) data.email = email;

  console.log('EDITUSERDATA', data);
  await DatabaseHandler.userCrud('PUT', data);
  // TODO get response and dispatch a notification
  toggle();
  // eslint-disable-next-line one-var

  return false;
};

const addEditUserLogic = {
  validateData,
  submitAddUser,
  submitEditUser,
};

export default addEditUserLogic;
