import {
  LatLngBounds, Point, PointExpression, LatLngExpression, LatLng,
} from 'leaflet';
import { Devices } from '../Typings/deviceTypes';

const pointIntPoly = require('point-in-polygon');//eslint-disable-line

const findAndSelectDevices = (devices: Devices[], bounds: LatLngBounds | null, centerPnt: Point | null, radius: number | null,
  polyPoints: LatLng[], selectedMapDevices: Devices[], dispatch?: (action: { type: string, payload: Devices[] | LatLng | unknown }) => void,
  shapes?: LatLng | Devices[] | unknown, mostRecentShape?: LatLng): boolean | Devices[] => {
  const selectedDevices: Devices[] = [];
  const devicePnts: Devices[] = [];
  /*
  devices.forEach((device) => {
    if (device.loc.coordinates.length) {
      devicePnts.push(device);
    }
  });

   */
  for (let i = 0; i < devicePnts.length; i += 1) {
    if (centerPnt && radius && devicePnts[i] !== undefined) {
      if (devicePnts[i] && centerPnt.distanceTo(devicePnts[i].loc.coordinates as PointExpression) < radius) {
        selectedDevices.push(devicePnts[i]);
      }
    }
    if (bounds) {
      if (bounds.contains(devicePnts[i].loc.coordinates as LatLngExpression)) {
        selectedDevices.push(devicePnts[i]);
      }
    }
    if (polyPoints) {
      // console.log('checking point in poly')
      if (pointIntPoly(devicePnts[i].loc.coordinates as LatLngExpression, polyPoints)) {//eslint-disable-line
        selectedDevices.push(devicePnts[i]);
      }
    }
  }
  const notInSelectedMap = selectedDevices.filter((element) => selectedMapDevices.indexOf(element) === -1);
  const totalSelected = [...selectedMapDevices, ...notInSelectedMap];

  if (dispatch === undefined) return selectedDevices;

  if (dispatch) {
    dispatch({ type: 'SELECTED_DEVICES', payload: [...totalSelected] });
    dispatch({
      type: 'ADD_SHAPE_DEVICES',
      payload: {
        lastCenter: mostRecentShape as unknown as LatLng,
        associatedDevices: selectedDevices as unknown as Devices[],
      },
    });
    dispatch({ type: 'MOST_RECENT_SHAPE', payload: {} });
  }
  return true;
};

const senderUtils = { findAndSelectDevices };

export default senderUtils;
