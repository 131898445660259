import axios, { AxiosResponse } from 'axios';
import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Point } from 'leaflet';
import { HexColorPicker } from 'react-colorful';
import Button from '../../Components/Button';
import channelLogic from './channelLogic';
import { RootState } from '../../Redux/reducers';
import ChannelMap from './ChannelMap';
import { Channel } from '../../Typings/channelTypes';

const duplicateChannelName = (
  channelName: string,
  channels: Channel[],
  id: string | undefined,
) => {
  if (!channels || !channels.length) {
    return false;
  }

  for (let i = 0; i < channels.length; i += 1) {
    if (channels[i].name === channelName) {
      if (id) {
        if (id && channels[i]._id !== id) {
          // if the username matches another user, but the id's also match then this user is just being edited
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
};

export const validateChannel = (channelName: string, channels: Channel[], channelType?: string,
  channelCoords?: { lat: number, lon: number }, id?: string):boolean => {
  const ifNotOnlySpace = /[a-zA-Z0-9]/.test(channelName);
  if (!ifNotOnlySpace) return true;
  if (channelName === undefined || channelName === null || channelName === '') return true;
  //if (duplicateChannelName(channelName, channels, id)) return true;
  if (channelType === 'geofence' && !channelCoords) return true;
  return false;
};

const AddEditChannelPage = (
  {
    channels,
    id,
    toggle,
    modalExpansion,
    setModalExpansion,
  }: {
    channels: Channel[],
    toggle: () => void,
    id?: string,
    modalExpansion: boolean,
    setModalExpansion: Dispatch<SetStateAction<boolean>>;
  }): JSX.Element => {
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const { geoEnabled } = useSelector((state: RootState) => state.sender);
  const [channelName, setChannelName] = useState('');
  const [channelStyle, setChannelStyle] = useState('#FFFFFF');
  const [channelColor, setChannelColor] = useState('#000000');
  const [channelSticky, setChannelSticky] = useState(false);
  const [channelChat, setChannelChat] = useState(false);
  //const [channelCoordinates, setChannelCoordinates] = useState<{ lat: number, lon: number } | undefined>(undefined);
  //const [channelCircle, setChannelCircle] = useState<
  //{ center: Point | number[] | null, radius: number | undefined }>({ center: null, radius: undefined });
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const channel = {
    name: channelName,
    background: channelStyle,
    text: channelColor,
    sticky: channelSticky,
    chat: channelChat,
    //type: channelType,
    //coordinates: channelCoordinates,
    //circle: channelCircle.radius !== undefined ? { center: 0, radius: channelCircle.radius } : undefined,
  };
  const [mapCenter, setMapCenter] = useState<{ lat: number, lng: number }>({ lat: 37.271, lng: -79.9414 });
  const [requiredChannelName, setRequiredChannelName] = useState(false);
  const [duplicateChannelNameError, setDuplicateChannelNameError] = useState(false);

  //const handleCircleCoordinatesChange = (newCoords: { lat: number, lon: number } | undefined) => setChannelCoordinates(newCoords);
  //const handleCircleRadiusChange = (newRadius: number | undefined) => setChannelCircle({ center: null, radius: newRadius });
  console.log('CHANNELS', channels);
  console.log('IDIDID', id);
  // TODO dont need to get channel by id
  useEffect(() => {
    console.log('IN THE ID USEEFFECT');
    const thisChannel = channels.find((chan) => {
      return chan._id === id;
    });
    if (thisChannel !== undefined) {
      setChannelName(thisChannel.name);
      setChannelStyle(thisChannel.style);
      setChannelColor(thisChannel.color);
      setChannelChat(thisChannel.chat);
      setChannelSticky(thisChannel.sticky);
    }
    /*
    if (id !== undefined) {
      channelLogic.getChannelById({
        id,
        setChannelName,
        setChannelStyle,
        setChannelColor,
        setChannelSticky,
        setChannelChat,
        //setChannelType,
        //setChannelCircle,
        //setChannelCoordinates,
        setErrorMessage,
      });
    }
     */
  }, [id]);

  useEffect(() => {
    if (navigator.geolocation && geoEnabled === 'true') {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setMapCenter({ lat, lng });
      });
    }
  }, [geoEnabled]);

  const getGeoEnabled = async () => {
    //const res: AxiosResponse = await axios.get('/api/v2/util/geo-enabled/');
    //dispatch({ type: 'GEO_ENABLED', payload: res.data.enabled as string });
    dispatch({ type: 'GEO_ENABLED', payload: false });
  };

  useEffect(() => {
    if (geoEnabled === undefined) {
      getGeoEnabled();
    }
  }, []);
  /*
  const handleGeoChange = (geo: boolean) => {
    if (geo) {
      setChannelType('geofence');
    } else {
      setChannelType('other');
      handleCircleRadiusChange(undefined);
      handleCircleCoordinatesChange(undefined);
    }
  };
  useEffect(() => {
    if (channelType && channelType === 'geofence') setModalExpansion(true);
    else setModalExpansion(false);
  }, [channelType]);
   */

  const watchChannelNameEdit = (nameEdit: string) => {
    if (duplicateChannelName(nameEdit, channels, id)) setDuplicateChannelNameError(true);
    if (!duplicateChannelName(nameEdit, channels, id)) setDuplicateChannelNameError(false);
  };

  const handleChannelNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChannelName(e.target.value);
    if (e.target.value === '') setRequiredChannelName(true);
    if (e.target.value !== '') setRequiredChannelName(false);
    watchChannelNameEdit(e.target.value);
  };

  return (
    <main>
      {errorMessage.length ? <div role="alert" aria-live="assertive" className="alert alert-danger">{errorMessage}</div> : null}
      <div className="outerFormContainer">
        <div className="container-no-gutters">
          <div className="row">
            <div className="col">
              <form className="form-center">
                <div className="form-group requiredFormGroupLabel">
                  <div className={!(!requiredChannelName && !duplicateChannelNameError) ? 'requiredLabelRed input-label' : 'input-label'}>
                    {!(!requiredChannelName && !duplicateChannelNameError) ? 'Channel Name' : 'Channel Name *'}
                  </div>
                  <p className="minorPrint">Symbols (i.e @, #, $...) are not allowed.</p>
                  <input
                    id="channel name input"
                    type="text"
                    value={channelName}
                    className={!(!requiredChannelName && !duplicateChannelNameError) ? 'form-control requiredInput' : 'form-control'}
                    onChange={(e) => handleChannelNameOnChange(e)}
                    aria-label={id ? `${channelName} is the current name for this channel. Do you wish to change it?`
                      : 'Required channel name input field. Please enter the name of channel here and submit button will no longer be disabled.'}
                    aria-required="true"
                    required
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="form-group">
                    <div className="input-label">Text Color</div>
                    <HexColorPicker
                      aria-label="color"
                      color={channelColor}
                      onChange={(color) => setChannelColor(color)}
                    />
                  </div>
                  <div className="form-group">
                    <div className="input-label">Background Color</div>
                    <HexColorPicker
                      aria-label="style"
                      color={channelStyle}
                      onChange={(color) => setChannelStyle(color)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="input-checkbox" className="input-label">
                    Sticky?
                    <input
                      id="input-checkbox"
                      type="checkbox"
                      name="input-checkbox"
                      className="input-checkbox"
                      checked={channelSticky}
                      onChange={() => setChannelSticky(!channelSticky)}
                      aria-label="channel sticky checkbox"
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="input-checkbox" className="input-label">
                    Chat?
                    <input
                      id="input-checkbox"
                      type="checkbox"
                      name="input-checkbox"
                      className="input-checkbox"
                      checked={channelChat}
                      onChange={() => setChannelChat(!channelChat)}
                      aria-label="channel chat checkbox"
                    />
                  </label>
                </div>
                {/*
                <div className="form-group">
                  <label htmlFor="input-checkbox" className="input-label">
                    Geo?
                    <input
                      id="input-checkbox"
                      type="checkbox"
                      name="input-checkbox"
                      className="input-checkbox"
                      checked={channelType === 'geofence'}
                      onChange={(e) => {
                        handleGeoChange(e.target.checked);
                        setModalExpansion(!modalExpansion);
                      }}
                      aria-label="channel-type-checkbox"
                    />
                  </label>
                </div>
                */}
              </form>
            </div>
            {/*
            {channelType === 'geofence'
              ? (
                <form className="form-center col">
                  <div className="form-group">
                    <ChannelMap
                      id={id}
                      mapCenter={mapCenter}
                      circleRadius={channelCircle.radius}
                      circleCoordinates={channelCoordinates}
                      handleCircleRadiusChange={handleCircleRadiusChange}
                      handleCircleCoordinatesChange={handleCircleCoordinatesChange}
                    />
                  </div>
                  <p className="mapHelpertxt">*Shape required to submit form. Drawing a new shape removes previous shape</p>
                </form>
              ) : null}
              */}
          </div>
          <div className="form-group">
            <Button ariaLabel="cancel button" text="Cancel" onClick={() => toggle()} type="button" className="cancel-button ml-0" />
            <Button
              text={id ? 'Edit Channel' : 'Add Channel'}
              onClick={id ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => channelLogic.handleSubmit(
                e, channel, setErrorMessage, dispatch, socket, false, toggle, id,
              )
                : (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => channelLogic.handleSubmit(
                  e, channel, setErrorMessage, dispatch, socket, true, toggle,
                )}
              type="submit"
              disabled={id
                ? validateChannel(channelName, channels, id)
                : validateChannel(channelName, channels )}
              ariaLabel="submit button"
              className="primary-button"
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default AddEditChannelPage;
