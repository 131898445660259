import { getAuth, signInWithCustomToken } from "firebase/auth";
const axios = require('axios');
import { initializeApp } from 'firebase/app';
const project = process.env.REACT_APP_FIREBASE_PROJECT;
const devTable = process.env.REACT_APP_FIREBASE_DEVTABLE;
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;


let dbURL;
let funcURL;
let firebaseApp;
let table;

// TODO move this, setting client, and exporting firebase app to a new file?
const initFirebase = () => {
  console.log('initFirebase');
  table = sessionStorage.getItem('clientTable');
  console.log('InitFirebase-TABLE', table);
  if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
  dbURL = `https://valert-${table}.firebaseio.com`;
  funcURL = `https://us-central1-${project}.cloudfunctions.net/`;
} else if (process.env.REACT_APP_FIREBASE_ENV === 'development') {
  dbURL = `http://127.0.0.1:9000/?ns=valert-${table}`;
  funcURL = `http://localhost:5001/${project}/us-central1/`;
}
const firebaseConfig = {
  databaseURL: dbURL,
  apiKey,
};
firebaseApp = initializeApp(firebaseConfig);
}

const setUserPermissions = (userRole) => {
  if (userRole === 'admin') {
    const permissionsArray = [
      'send_message',
      'sender',
      'user',
      'alert',
      'channel',
      'device',
      'file',
      'message',
      'role',
      'styles',
      'system',
      'token',
      'none',
      'transaction',
      'geo_fetch_devices',
      'geo_send_alert',
    ];
    sessionStorage.setItem('permissions', JSON.stringify(permissionsArray));
  } else {
    sessionStorage.setItem('permissions', JSON.stringify(['none']));
  }
};
const userLogin = async (username, password, dispatch) => {
    const payload = { username, password };
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Table': table
        }
    }
    try {
      const response = await axios.post(`${funcURL}login`, payload, options);
      const token = response.data.token;
      console.log(token);
      const auth = getAuth(firebaseApp);
      const userCredential = await signInWithCustomToken(auth, token);
      console.log('credential', userCredential);
      const tokenResult = await userCredential.user.getIdTokenResult();
      console.log('tokenResult', tokenResult);
      console.log('role', tokenResult.claims.role);

      sessionStorage.setItem('authToken', userCredential.user.accessToken);
      sessionStorage.setItem('authorizedUser', userCredential.user.uid)
      sessionStorage.setItem('userRole', tokenResult.claims.role);
      // TODO set and implement permissions
      setUserPermissions(tokenResult.claims.role);
      dispatch({ type: 'LOGIN', payload: true });

    } catch (error) {
      // TODO pop an error message
      console.log(error);
    }
}

export { firebaseApp, initFirebase, userLogin };