import React from 'react';
import superagent, { Response } from 'superagent';
import FileSaver from 'file-saver';
import axios, { AxiosResponse } from 'axios';
import Card from '../../Components/Card';
import { ToastType } from '../../Typings/toastTypes';
import { Channel } from '../../Typings/channelTypes';
import {
  TokenType, Role, AccessControls,
} from '../../Typings/adminTypes';
import type SocketController from '../../Utils/SocketController';

export const ADMIN = 'admin';
export const SENDER = 'sender';
export const SENDER_ACCESS_CONTROL = 'geo_send_alert';
export const MOBILE = 'mobile';

const mapChannelNames = (roleChannels: string[], channels: Channel[]): string[] => {
  const channelInfo: string[] = [];

  if (channels && roleChannels) {
    const filtered = channels.filter((curChannel: Channel) => curChannel._id && roleChannels.includes(curChannel._id));
    filtered.forEach((channel: Channel) => {
      channelInfo.push(channel.name);
    });
  }

  return channelInfo;
};

const getTokens = async (dispatch: (action: { type: string, payload?: TokenType[] }) => void): Promise<boolean> => {
  try {
    const res: AxiosResponse<TokenType[]> = await axios.get('/api/v2/core/tokens/', {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}`,
      },
    });
    if (res.status === 200) {
      dispatch({ type: 'GET_TOKENS', payload: res.data });
      dispatch({ type: 'VISIT_TOKENS' });
      return true;
    }
    return false;
  } catch (err) {
    // TODO: Put status message that says error getting tokens
    return false;
  }
};

const getAccessControls = async (dispatch: (action: { type: string, payload: AccessControls[] }) => void): Promise<boolean> => {

  const ac = [
	{
		"_id": "5df90581605627001b10a181",
		"create": true,
		"update": true,
		"delete": true,
		"name": "user",
		"__v": 0
	},
	{
		"_id": "5df90581605627001b10a182",
		"create": false,
		"update": false,
		"delete": false,
		"name": "geo_send_alert",
		"__v": 0
	},
	{
		"_id": "5df90581605627001b10a183",
		"create": false,
		"update": false,
		"delete": false,
		"name": "geo_fetch_devices",
		"__v": 0
	},
	{
		"_id": "5df90581605627001b10a184",
		"create": true,
		"update": true,
		"delete": true,
		"name": "channel",
		"__v": 0
	},
	{
		"_id": "5df90581605627001b10a185",
		"create": true,
		"update": true,
		"delete": true,
		"name": "styles",
		"__v": 0
	},
	{
		"_id": "5df90581605627001b10a186",
		"create": true,
		"update": true,
		"delete": true,
		"name": "file",
		"__v": 0
	},
	{
		"_id": "5df90581605627001b10a187",
		"create": true,
		"update": true,
		"delete": true,
		"name": "system",
		"__v": 0
	},
	{
		"_id": "5df90581605627001b10a188",
		"create": true,
		"update": true,
		"delete": true,
		"name": "device",
		"__v": 0
	},
	{
		"_id": "5df90581605627001b10a189",
		"create": true,
		"update": true,
		"delete": true,
		"name": "token",
		"__v": 0
	}
]
  dispatch({ type: 'GET_ACCESS_CONTROLS', payload: ac });
  return true;
};

// filter roles to only show ADMIN, SENDER, and MOBILE roles
const filterRoles = (roles: Role[]) => roles.filter((r) => r.name === ADMIN || r.name === SENDER || r.name === MOBILE);

const getRoles = async (dispatch: (action: { type: string, payload?: Role[] }) => void): Promise<boolean> => {
  //let res: AxiosResponse<Role[]>;
  const roles = [
	{
		"_id": "5df90580605627001b10a17a",
		"permissions": [
			"send_message",
			"sender",
			"user",
			"alert",
			"channel",
			"device",
			"file",
			"message",
			"role",
			"styles",
			"system",
			"token",
			"none",
			"transaction",
			"geo_fetch_devices",
			"geo_send_alert"
		],
		"channels": [
			"5f2ad1f3477022364bf6e1e1",
			"5f2ad76d477022364bf6e1e6",
			"639b79f61b600b1f4971fb09",
			"63b5b90bd6112a33ddffd16d",
			"639b926b04f83f23810a52bb",
			"63cea8dece40ee7f7531d59d",
			"63cea8e4ce40ee7f7531d59f",
			"63cea8d8ce40ee7f7531d59b",
			"63cea8d2ce40ee7f7531d599",
			"63cea8ccce40ee7f7531d597"
		],
		"name": "admin",
		"__v": 0
	},
	{
		"_id": "5f2ad206477022364bf6e1e2",
		"permissions": [
			"none"
		],
		"channels": [
			"5f2ad1f3477022364bf6e1e1",
			"5f2ad76d477022364bf6e1e6"
		],
		"name": "mobile",
		"__v": 0
	},
    {
      "_id": "5f2ad206477022364bf6e1e2a",
		"permissions": [
			"none"
		],
		"channels": [
			"5f2ad1f3477022364bf6e1e1",
			"5f2ad76d477022364bf6e1e6"
		],
		"name": "sender",
		"__v": 0
    },
]
  dispatch({ type: 'GET_ROLES', payload: roles });
  dispatch({ type: 'VISIT_ROLES' });
  return true;
};

const restoreDB = async (
  event: React.ChangeEvent<HTMLInputElement>,
  dispatch: (action: { type: string, payload: ToastType }) => void,
): Promise<boolean> => {
  const dbUploadConfig = {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` },
  };

  const target = event.target as HTMLInputElement;
  const { files } = target;

  const file = files?.item(0) || null;

  const dbPayload = {
    file,
    name: file && file.name,
  };

  const addPayload = new FormData();
  if (dbPayload.name && dbPayload.file) {
    addPayload.append('file', dbPayload.file, dbPayload.name);
  }

  try {
    const res = await axios.post('/api/v2/core/system/restore/db', addPayload, dbUploadConfig);
    if (res.status === 200) {
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          toastType: 'success',
          heading: 'Database',
          message: 'Database downloaded\'',
          autoDismiss: true,
        },
      });
      return true;
    }
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Database',
        message: 'Error downloading database',
        autoDismiss: true,
      },
    });
    return false;
  }
  return false;
};

const downloadFile = async (
  type: string,
  dispatch: (action: { type: string; payload: Channel | ToastType }) => void,
): Promise<boolean> => {
  interface CustomResponse extends Response{
    header: {
      'content-disposition': string
    }
  }
  let result: CustomResponse, fileName: string, downloadType: string;
  const config = { Accept: 'application/json', Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` };
  downloadType = 'Database';
  if (type === 'log') downloadType = 'Logs';
  try {
    result = await superagent.get(`/api/v2/core/system/download/${type}`).responseType('blob').set(config);
    fileName = result.header['content-disposition'];
    fileName = fileName.replace('"', '');
    fileName = fileName.replace('"', '');
    const fArr = fileName.split('filename=');
    FileSaver.saveAs(result.body, fArr[1]);
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'success',
        heading: `${downloadType}`,
        message: `${downloadType} downloaded`,
        autoDismiss: true,
      },
    });
    return true;
  } catch (e) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: `${downloadType}`,
        message: `Error downloading ${downloadType}`,
        autoDismiss: true,
      },
    });
    return false;
  }
};

const createToken = async (
  name: string,
  dispatch: (action: { type: string; payload: Channel | ToastType | TokenType[] }) => void,
  socket: SocketController,
): Promise<boolean> => {
  const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` } };
  let trimmedName = name.replace(/^[ \t]+/, '');
  trimmedName = trimmedName.replace(/[ \t]+$/, '');

  try {
    const res = await axios.post('/api/v2/core/tokens/', { name: trimmedName }, config);
    const errMessage = res.status === 401 ? 'Unauthorized' : `Error adding ${name} token`;
    if (res.status === 200) {
      socket.emitNewToken(res.data.token);
      return true;
    }
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Token',
        message: `${errMessage}`,
        autoDismiss: true,
      },
    });
    return false;
  } catch (e) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Token',
        message: `Error adding ${name} token`,
        autoDismiss: true,
      },
    });
    return false;
  }
};

const editItem = async (
  id: string,
  data: Role | Channel | TokenType,
  type: string,
  dispatch: (action: { type: string; payload: Channel | ToastType }) => void,
): Promise<boolean> => {
  const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` } };
  let res: AxiosResponse;
  try {
    res = await axios.put(`/api/v2/core/${type}/${id}`, data, config);
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: `${type[0].toUpperCase()}${type.substring(1)}`,
        message: `Error editing ${type}`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error editing ${type}`;
  if (res.status === 200) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'success',
        heading: `${type}`,
        message: `${type} edited`,
        autoDismiss: true,
      },
    });
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: `${type}`,
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

const showDelete = (
  id: string,
  name: string,
  setName: (stateName: string) => void,
  setId: (stateId: string) => void,
  setshowRoleDelete: (show: boolean) => void,
):void => {
  setId(id);
  setName(name);
  setshowRoleDelete(true);
};

const deleteItem = async (
  { id, name } : { id: string, name: string },
  type: string,
  dispatch: (data: { type: string, payload: string | ToastType }) => void,
  socket: SocketController,
  reRender?: (data: boolean) => void,
  render?: boolean,
): Promise<boolean> => {
  const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` } };
  let res: AxiosResponse;
  try {
    res = await axios.delete(`/api/v2/core/${type}/${id}`, config);
    const errMessage = res.status === 401 ? 'Unauthorized' : `Error deleting ${name}`;
    if (res.status === 200) {
      if (render !== undefined && reRender !== undefined) reRender(!render);
      if (type === 'tokens') socket.emitDeleteToken(id, name);
      if (type === 'roles') socket.emitDeleteRole(id, name);
      return true;
    }
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: `${type[0].toUpperCase()}${type.substring(1)}`,
        message: `${errMessage}`,
        autoDismiss: true,
      },
    });
    return false;
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: `${type[0].toUpperCase()}${type.substring(1)}`,
        message: `Error deleting ${name}`,
        autoDismiss: true,
      },
    });
    return false;
  }
};

const toggleModal = (showModal: (data: boolean) => void, modal: boolean):void => {
  showModal(!modal);
};

const refreshToken = async (
  { id, name } :{ id: string, name: string },
  dispatch: (action: { type: string, payload?: ToastType | TokenType[], payload2?: TokenType }) => void,
  socket: SocketController,
): Promise<boolean> => {
  try {
    const res = await axios.put(`/api/v2/core/tokens/${id}`, {
      refresh: true,
    }, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}`,
      },
    });
    const errMessage = res.status === 401 ? 'Unauthorized' : `Error refreshing ${name} token`;
    if (res.status === 200) {
      socket.emitRefreshToken(res.data.token);
      return true;
    }
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Tokens',
        message: `${errMessage}`,
        autoDismiss: true,
      },
    });
    return false;
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Tokens',
        message: `Error refreshing ${name} token`,
        autoDismiss: true,
      },
    });
    return false;
  }
};

const renderCard = (
  myRoles: Role[],
  history: { push: (path: string) => void },
  showDeleteModal: (id: string, name: string) => void,
  channels: Channel[],
  showEditModal: (id: string) => void,
): JSX.Element[] => {
  const rolesComp = myRoles.map((myRole: Role) => {
    const roleContent = [
      {
        heading: myRole.name,
        data: [],
      },
      {
        heading: 'channels',
        data: mapChannelNames(myRole.channels, channels),
      },
      {
        heading: 'permissions',
        data: myRole.permissions,
      },
    ];
    return (
      <div key={myRole._id}>
        <Card
          id={myRole._id}
          name={myRole.name}
          handleDelete={(id, name) => showDeleteModal(id, name)}
          handleEdit={(id) => showEditModal(id)}
          content={roleContent}
          hideDelete={myRole.name === 'admin'}
          ariaLabel={`${myRole.name} Role Card.`}
        />
      </div>
    );
  });

  return rolesComp;
};

export {
  createToken, downloadFile, restoreDB, getRoles, getTokens, mapChannelNames, editItem,
  deleteItem, showDelete, toggleModal, refreshToken, getAccessControls, renderCard,
};
