import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, query, orderByChild, equalTo, onChildAdded, onChildRemoved, onChildChanged } from "firebase/database";
import {firebaseApp} from "./authHandler";
import axios from 'axios';
const project = process.env.REACT_APP_FIREBASE_PROJECT;
const devTable = process.env.REACT_APP_FIREBASE_DEVTABLE;

let dbURL;
let funcURL;

class DatabaseHandler {
  static database;
  static dispatch;
  static table;

  static initDatabaseHandler(dispatch) {
    this.dispatch = dispatch;
    if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
      this.table = sessionStorage.getItem('clientTable');
      dbURL = `https://valert-${this.table}.firebaseio.com`;
      funcURL = `https://us-central1-${project}.cloudfunctions.net/`;
    } else if (process.env.REACT_APP_FIREBASE_ENV === 'development') {
      this.table = devTable;
      dbURL = `http://127.0.0.1:9000/?ns=valert-${this.table}`;
      funcURL = `http://localhost:5001/${project}/us-central1/`;
    }

    this.database = getDatabase(firebaseApp);
    const settingsRef = ref(this.database, 'settings/');
    onValue(settingsRef, (snapshot) => {
      console.log('SETTINGS', snapshot.val().geo);
      this.dispatch({ type: 'GEO_ENABLED', payload: snapshot.val().geo });
    });

    // TODO put collection listeners in a seperate listener w/ global storage (for turning off))
    // TODO look at using childAdded, deleted listeners instead of onValue?
    const userRef = ref(this.database, 'users/');
    onValue(userRef, (snapshot) => {
      const userArray = [];
      snapshot.forEach((data) => {
        console.log(data.key);
        console.log(data.val());
        const chanArray = [];
        if (data.val().channels !== false) {
          console.log(data.val().channels);
          for (const [key, value] of Object.entries(data.val().channels)) {
            console.log('IN THE USER CHANNELS LOOP');
            console.log(key, value);
            const chanObj = {
              _id: key,
              admin: value.admin,
            }
            chanArray.push(chanObj);
          }
        }
        const userObj = {
          _id: data.key,
          username: data.val().username,
          email: data.val().email,
          role: data.val().role,
          channels: chanArray
        }
        userArray.push(userObj);
      });
      console.log('USERARRAY', userArray);
      this.dispatch({ type: 'GET_USERS', payload: userArray });
      this.dispatch({ type: 'VISIT_USERS' });
    });

    const channelRef = ref(this.database, 'channels/');
    onValue(channelRef, (snapshot) => {
      const chanArray = [];
      snapshot.forEach((data) => {
        console.log(data.key);
        console.log(data.val());
        const chanObj = {
          _id: data.key,
          name: data.val().name,
          color: data.val().text,
          style: data.val().background,
          chat: data.val().chat,
          sticky: data.val().sticky,
        }
        chanArray.push(chanObj);
      });
      console.log('CHANARRAY', chanArray);
      this.dispatch({ type: 'GET_CHANNELS', payload2: chanArray });
      this.dispatch({ type: 'VISIT_CHANNELS' });
    });

    // Initialize device array
    let deviceArray = [];

    // Function to handle devices
    // Array to store batched updates
    const batchedUpdates = {
      add: [],
      update: [],
      remove: [],
    };

    // Function to handle devices
    const handleDevice = (data, action) => {
      console.log(data.key);
      console.log(data.val());
      const deviceKey = data.val().key;

      // Skip if deviceKey is not set
      if (!deviceKey) return;

      const deviceObj = {
        _id: data.key,
        location: data.val().location[deviceKey],
      };

      console.log('DEVICEOBJ', deviceObj);
      if (deviceObj.location) {
        batchedUpdates[action].push(deviceObj);
      }
    };

    // Function to dispatch batched updates
    const dispatchBatchedUpdates = () => {
      if (batchedUpdates.add.length > 0) {
        this.dispatch({ type: 'ADD_REGISTERED_DEVICES', payload: batchedUpdates.add });
        batchedUpdates.add = [];
      }
      if (batchedUpdates.update.length > 0) {
        this.dispatch({ type: 'UPDATE_REGISTERED_DEVICES', payload: batchedUpdates.update });
        batchedUpdates.update = [];
      }
      if (batchedUpdates.remove.length > 0) {
        this.dispatch({ type: 'DELETE_REGISTERED_DEVICES', payload: batchedUpdates.remove });
        batchedUpdates.remove = [];
      }
    };
    const batchInterval = setInterval(dispatchBatchedUpdates, 5000);

    // Set devices listeners
    const deviceRef = query(ref(this.database, 'devices/'), orderByChild('location/geo'), equalTo(true));

    onChildAdded(deviceRef, (snapshot) => {
      handleDevice(snapshot, 'add');
    });

    onChildChanged(deviceRef, (snapshot) => {
      handleDevice(snapshot, 'update');
    });

    onChildRemoved(deviceRef, (snapshot) => {
      handleDevice(snapshot, 'remove');
    });

    this.initialized = true;
  }

  static async userCrud(crudOperation, payload) {
    const authToken = sessionStorage.getItem('authToken');
    console.log(crudOperation, payload);
    // TODO this table header will be set by an env variable
    const config = { headers: { table: this.table, authorization: authToken }};
    let res;
    switch (crudOperation) {
      case 'POST':
        console.log('PAYLOAD', payload);
        res = await axios.post(funcURL + 'userCrud', payload, config);
        break;
      case 'PUT':
        console.log('PAYLOAD', payload);
        res = await axios.put( funcURL + `userCrud/${payload.id}`,
          payload, config);
        break;
      case 'DELETE':
        res = await axios.delete(funcURL + `userCrud/${payload.id}`, config);
        break;
    }
    console.log('RES', res);
    // TODO return something
  }

  static async channelCrud(crudOperation, payload) {
    console.log(crudOperation, payload);
    const config = { headers: { table: this.table }};
    let res;
    switch (crudOperation) {
      case 'POST':
        res = await axios.post(funcURL + 'channelCrud', payload, config);
        break;
      case 'PUT':
        res = await axios.put(funcURL + `channelCrud/${payload.id}`,
          payload, config);
        break;
      case 'DELETE':
        res = await axios.delete(funcURL + `channelCrud/${payload.id}`, config);
        break;
    }
    console.log('RES', res);
    // TODO return something
  }

  static async sendAlert(payload) {
    const config = { headers: { table: this.table }};
    const res = await axios.post(funcURL + 'sendAlert', payload, config);
  }
}

export default DatabaseHandler