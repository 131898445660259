import axios, { AxiosResponse } from 'axios';
import { Role, AccessControls } from '../../Typings/adminTypes';
import { User } from '../../Typings/userTypes';
import { ToastType } from '../../Typings/toastTypes';
import type SocketController from '../../Utils/SocketController';
import DatabaseHandler from "../../Firebase/databaseHandler";

export type UserChannelRes = {
  active: boolean;
  _id: string;
  channel?: string;
};

const mapAccessControls = (userAccessControls: string[], accessControls: AccessControls[]): string[] => {
  const accessControlInfo: string[] = [];

  if (accessControls && userAccessControls) {
    const filtered = accessControls.filter((curAccessControl: AccessControls) => userAccessControls.includes(curAccessControl._id));
    filtered.forEach((accessControl: AccessControls) => {
      accessControlInfo.push(accessControl.name);
    });
  }
  return accessControlInfo;
};

const isAdmin = (admin: boolean): string => {
  const adminString = admin ? 'Admin' : '';
  return adminString;
};

const checkAdminPassword = async (userID: string, setShowPassModal: React.Dispatch<React.SetStateAction<boolean>>): Promise<boolean> => {
  /*
  const loggedInUser:any = await getUserById(userID); //eslint-disable-line
  if (loggedInUser.user.username === 'admin' && loggedInUser.user.passwordReset === false) {
    setShowPassModal(true);
    return true;
  }
   */
  return true;
};

const mapRoles = (userRole: string, roles: Role[]): string => {
  let filteredRole: Role | undefined;
  if (roles && userRole) {
    filteredRole = roles.find((r: Role) => r._id === userRole);
    if (filteredRole) return `Role: ${filteredRole.name}`;
    return '';
  }
  return '';
};

// TODO this will not be used.
/*
const getUsers = async (dispatch: (action: { type: string, payload?: User[] }) => void): Promise<boolean> => {
  let res: AxiosResponse<User[]>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    res = await axios.get('/api/v2/user/users?username[$regex]=^((?!-).)*$', config);
  } catch (err) {
    return false;
  }
  if (res.status === 200) {
    dispatch({ type: 'GET_USERS', payload: res.data });
    dispatch({ type: 'VISIT_USERS' });
    return true;
  }
  return false;
};
 */

const getDerivativeUsers = async (template: string): Promise<AxiosResponse<User[]>> => {
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  const res: AxiosResponse<User[]> = await axios.get(`api/v2/user/users?username[$regex]=^${template}`, config);
  return res;
};

const getUserById = async (id: string): Promise<{ user: User } | boolean> => {
  let res: AxiosResponse<{ user: User }>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    res = await axios.get(`api/v2/user/users/${id}`, config);
  } catch (err) {
    return false;
  }
  return res.data;
};

const deleteUser = async (
  { delId, delName } : { delId: string, delName: string },
  dispatch: (data: { type: string, payload: string | ToastType }) => void,
  socket: SocketController,
): Promise<boolean> => {
  console.log(delId, delName);
  await DatabaseHandler.userCrud('DELETE', {id: delId});
  /*
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error deleting ${delName} user`;
  if (res.status === 200) {
    socket.emitUserDeletion(delId, delName);
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'Users',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
   */
  return true;
};

const usersLogic = {
  getDerivativeUsers,
  deleteUser,
  mapAccessControls,
  mapRoles,
  getUserById,
  isAdmin,
  checkAdminPassword,
};

export default usersLogic;
