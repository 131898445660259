import React from 'react';
import { useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Devices } from '../Typings/deviceTypes';
import DeviceMarker from '../Components/DeviceMarker';

const DeviceMarkersInView = ({ devices }: { devices: Devices[] }): JSX.Element => {
  const map = useMap();

  const devicesInView = (): Devices[] => {
    const bounds = map.getBounds();
    return devices.filter((device) => {
      const { lat, lng } = device.location;
      return bounds.contains([lat, lng]);
    });
  };

  const renderDevices = (devices: Devices[]): (JSX.Element | null)[] => {
    devices.sort((a, b) => b.location.reported - a.location.reported);
    const lastReportedDevices = devices.slice(0, 5000);
    return lastReportedDevices.map((device) => (
      <DeviceMarker key={device._id} device={device} />
    ));
  };

  return (
    <MarkerClusterGroup>
      {renderDevices(devicesInView())}
    </MarkerClusterGroup>
  );
};

export default DeviceMarkersInView;