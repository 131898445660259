import React from 'react';
import { Redirect } from 'react-router-dom';
import StatusComponent from './Status';
import InputField from './Input';
import Button from './Button';

type LoginProps = {
  title: string;
  subtitle: string;
  isAuthenticated: boolean;
  userInputValue: string;
  passInputValue: string;
  onUserInput: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
  onPassInput: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
  validateLogin: () => boolean;
  handleLogin: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => Promise<boolean>;
  statusMessage?: string;
  statusType?: string;
  logo?: string;
};

const Login = ({
  title,
  subtitle,
  isAuthenticated,
  userInputValue,
  passInputValue,
  onUserInput,
  onPassInput,
  validateLogin,
  handleLogin,
  statusMessage,
  statusType,
  logo,
}: LoginProps): JSX.Element => {
  if (isAuthenticated === false) {
    return (
      <main className="fullScreenBackground">
        <div className="loginBackground">
          <h1 className="loginWelcome">
            Welcome to
            <br />
            V-Alert (DEV-2)
          </h1>
          <p className="loginSubWelcome">We are glad you&apos;re back</p>
        </div>
        <div className="loginContainer">
          {logo ? (
            <img alt="login logo" src={logo} className="loginLogo" />
          ) : null}
          <div className="loginText">
            <div className="loginTitle">
              <h1>{title}</h1>
              <hr className="titleLine" />
            </div>
            <div className="loginSubtitle">
              <p>{subtitle}</p>
            </div>
            {statusMessage !== undefined && statusType !== undefined ? (
              <StatusComponent message={statusMessage} type={statusType} />
            ) : null}
            <form className="login-group">
              <InputField
                placeholder="Username"
                htmlFor="Username:"
                label="Username:"
                value={userInputValue}
                onChange={onUserInput}
                className="input-login-user"
                ariaLabel="Username input"
              />
              <InputField
                placeholder="********"
                htmlFor="Password:"
                label="Password:"
                value={passInputValue}
                onChange={onPassInput}
                className="input-login-password"
                type="password"
                ariaLabel="Password input"

              />
              <Button
                text="Login"
                type="submit"
                className="button loginButton"
                disabled={validateLogin()}
                onClick={(e) => handleLogin(e)}
              />
            </form>
          </div>
        </div>
      </main>
    );
  }
  return <Redirect to="/dashboard" />;
};

export default Login;
