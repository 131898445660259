import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TokenContainer from './TokenContainer';
import { RootState } from '../../Redux/reducers';
import { createToken, getTokens, deleteItem } from './adminLogic';
import DeleteModal from '../../Components/DeleteModal';

const valertTier = process.env.REACT_APP_VALERT_TIER;

const Admin = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    tokens, tokensVisited,
  } = useSelector((state: RootState) => state.admin);
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [showTokenDelete, setShowTokenDelete] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (!tokensVisited || !tokens.length) getTokens(dispatch);
  }, [render]);

  const toggleTokenModal = () => {
    setShowTokenDelete(!showTokenDelete);
  };

  const deleteToken = () => {
    deleteItem({ id, name }, 'tokens', dispatch, socket, setRender, render);
  };

  const showTokenDeleteModal = (tokenId: string, tokenName: string) => {
    setId(tokenId);
    setName(tokenName);
    setShowTokenDelete(true);
  };

  return (
    <main className="outerFlexContainer">
      {showTokenDelete && <DeleteModal open={showTokenDelete} toggleModal={toggleTokenModal} deleteTitle="token" deleteFunction={deleteToken} /> }
      {valertTier === 'pro' && (
        <div className="token-container">
          <TokenContainer
            contents={tokens}
            createToken={createToken}
            reRender={render}
            reRenderFunc={setRender}
            showDelete={showTokenDeleteModal}
          />
        </div>
      )}
    </main>
  );
};

export default Admin;
