import React, { MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import jwt from 'jwt-simple';
import Login from '../Components/LoginComponent';
import { RootState } from '../Redux/reducers/index';
import { userLogin } from '../Firebase/authHandler';

const loginAPI = '/api/v2/core/auth/login';

const checkAuth = (dispatch: ({ type, payload }: { type: string, payload: boolean })=> void): boolean => {
  if (sessionStorage.getItem('authToken')) {
    dispatch({ type: 'LOGIN', payload: true });
  }
  return true;
};

export const adminAccess = (permission: string[]): boolean => {
  if (
    permission.indexOf('role') !== -1
    || permission.indexOf('token') !== -1
    || permission.indexOf('transaction') !== -1
  ) {
    return true;
  }
  return false;
};

export const senderAccess = (permission: string[]): boolean => {
  if ((permission.indexOf('sender') !== -1 || permission.indexOf('send_message') !== -1)) {
    return true;
  }
  return false;
};

// TODO re-set permissions here
export const hasAccess = (path: string, permissions: string[]): boolean => {
  switch (path) {
    case '/':
      return true;
    case '/login':
      return true;
    case '/dashboard':
      return true;
    case '/sender':
      //return senderAccess(permissions);
      return true;
    case '/users':
      //return permissions.indexOf('user') !== -1;
      return true;
    case '/channels':
      //return permissions.indexOf('channel') !== -1;
      return true;
    case '/files':
      //return permissions.indexOf('file') !== -1 && permissions.indexOf('channel') !== -1;
      return true;
    case '/admin':
      //return adminAccess(permissions);
      return true;
    case '/styles':
      //return permissions.indexOf('styles') !== -1;
      return true;
    case '/mobileActivation':
      //return adminAccess(permissions);
      return true;
    default:
      return false;
  }
};

const LoginContainer = (): JSX.Element => {
  const [statusMessage, setStatusMessage] = React.useState('');
  const [statusType, setStatusType] = React.useState('');
  const [userInputValue, setUserInputValue] = React.useState('');
  const [passInputValue, setPassInputValue] = React.useState('');

  const { loggedIn } = useSelector((state: RootState) => state.login);
  const loginDispatch = useDispatch();

  React.useEffect(() => {
    checkAuth(loginDispatch);
  }, []);

  const onUserInput = (e: React.ChangeEvent<HTMLInputElement>): boolean => {
    e.preventDefault();
    setUserInputValue(e.target.value);
    return true;
  };

  const onPassInput = (e: React.ChangeEvent<HTMLInputElement>): boolean => {
    e.preventDefault();
    const name = e.target.value.replace(/\s/g, '');
    setPassInputValue(name);
    return true;
  };

  const handleLogin = async (e:  React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => { //eslint-disable-line
    // TODO move this to a new file in firebase directory
    if (e !== undefined) e.preventDefault(); // eslint-disable-line
    const res = await userLogin(userInputValue, passInputValue, loginDispatch);
    /*
    let funcURL = '';
    if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
        funcURL = 'https://us-central1-valert-firebase.cloudfunctions.net/login';
    } else if (process.env.REACT_APP_FIREBASE_ENV === 'development') {
      funcURL = 'http://localhost:5001/valert-firebase/us-central1/login';
    }
    let r,
      trimmedName = userInputValue.replace(/^[ \t]+/, '');
    trimmedName = trimmedName.replace(/[ \t]+$/, '');
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Table': sessionStorage.getItem('clientTable') || 'testing'
        }
    }
    const payload = { username: trimmedName, password: passInputValue };
    try {
      r = await axios.post(funcURL, payload, options);
      console.log(r);
      if (r.status === 200) {
        const res = r;
        //sessionStorage.setItem('authToken', res.data.token);
        const secret = process.env.REACT_APP_JWT_SECRET;
        const decoded = secret ? jwt.decode(res.data.token, secret) as {
          channels: string[], permissions: string[], userId: string, role: string
        } : null;
        if (decoded !== null) {
          console.log('DECODED', decoded);
         // console.log('userRole', res.data.role);
          sessionStorage.setItem('authorizedUser', decoded.userId);
          sessionStorage.setItem('userRole', res.data.role);
          //sessionStorage.setItem('channels', JSON.stringify(decoded.channels));
          sessionStorage.setItem('permissions', JSON.stringify(decoded.permissions));
        } else {
          sessionStorage.setItem('channels', '');
          sessionStorage.setItem('permissions', '');
        }
        //loginDispatch({ type: 'LOGIN', payload: true });
        return true;
      }
      setStatusMessage('Error logging in');
      setStatusType('error');
      return false;
    } catch (err) {
      setStatusMessage('Please enter a valid username or password');
      setStatusType('error');
      return false;
    }
     */
  };

  const validateInput = (): boolean => {
    if (userInputValue.length <= 1 || passInputValue.length <= 1) {
      return true;
    }
    if (userInputValue.includes('@')) {
      return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userInputValue);
    }
    return false;
  };

  if (loggedIn === false) {
    return (
      <Login
        title="LOGIN"
        subtitle="To get started, enter your credentials below"
        isAuthenticated={loggedIn}
        userInputValue={userInputValue}
        passInputValue={passInputValue}
        onUserInput={(e: React.ChangeEvent<HTMLInputElement>): boolean => onUserInput(e)}
        onPassInput={(e: React.ChangeEvent<HTMLInputElement>): boolean => onPassInput(e)}
        validateLogin={validateInput}
        handleLogin={handleLogin}
        statusMessage={statusMessage}
        statusType={statusType}
      />
    );
  }
  return <Redirect to="/dashboard" />;
};

export default LoginContainer;
