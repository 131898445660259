/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint no-nested-ternary: "off" */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import dashboardLogic, { AlertFetchType } from './dashboardLogic';
import usersLogic from '../User/usersLogic';
import Button from '../../Components/Button';
import { Alert, AlertDetail } from '../../Typings/alertTypes';
import { Channel } from '../../Typings/channelTypes';
import { RootState } from '../../Redux/reducers';
import ToggleButton from '../../Components/toggleButton';
import channelLogic from '../Channels/channelLogic';
import ChannelSelectorComponent from '../../Components/ChannelSelector';
import { fetchRegisteredDevices } from '../../Utils/deviceUtils';
import Analytics from '../../Components/Analytics';
import AlertCard from '../../Components/AlertCard';
import Calendar from '../../Components/Calendar';
import DateSeparator from '../../Components/DateSeparator';
import Pill from '../../Components/Pill';
import PassChangeModal from './passChangeModal';
import SingleAlertAnalytics from '../../Components/SingleAlertAnalytics';
import Status from '../../Components/Status';

const DashboardContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [adminId, setAdminId] = useState('');
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [totalDocs, setTotalDocs] = useState(10);
  const [errorMessage, setErrorMessage] = useState('');
  const [isToggled, setToggled] = useState(true);
  const [showingByDate, setShowingByDate] = useState(false);
  const [channelPills, setChannelPills] = React.useState<Channel[]>([]);
  const [dateAlerts, setDateAlerts] = useState<Alert[]>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [dropdownOption, setDropDownOption] = useState('Filter by: text');
  const [showPassModal, setShowPassModal] = useState(false);
  const [passModalCalled, setPassModalCalled] = useState(false);
  const {
    alerts, activeUsers, filteredAlerts, alertDetail,
  } = useSelector((state: RootState) => state.dashboard);
  const { devices } = useSelector((state: RootState) => state.sender);
  const { channels } = useSelector((state: RootState) => state.channel);

  // Helpers
  const setSuccessfulStates = (res?: AlertFetchType): boolean => {
    setErrorMessage('');
    if (res?.data) {
      setCurrent(res.data?.page);
      setHasNextPage(res.data?.hasNextPage);
      setHasPrevious(res.data?.hasPrevPage);
      setTotalDocs(res.data?.totalDocs);
    }
    return true;
  };

  const buttonToggled = () => setToggled(!isToggled);
  const dropdownToggle = () => setDropDownOpen(!dropdownToggle);
  const togglePassModal = () => { setShowPassModal(!showPassModal); };

  const updateAlertDetailsDisplay = (alertId: string) => {
    if (alertId === '') {
      dashboardLogic.clearAlertDetail({ dispatch });
    } else {
      dashboardLogic.getAlertDetail({
        dispatch, setErrorMessage, setSuccessfulStates, alertId,
      });
    }
  };

  const renderChannels = (alert: Alert | AlertDetail): JSX.Element[] => {
    const channelList = alert.channels.map((channel: string) => (
      <Pill
        key={`${alert._id}${channel}`}
        content={channel}
        backgroundColor="#06376C"
        textColor="white"
      />
    ));
    return channelList;
  };

  const renderAlertSection = (arrayOfAlerts: Alert[]): JSX.Element => {
    let showSeparator = false,
      prevSent = moment(arrayOfAlerts[0].sent).format('DD-MM-YY');

    const allAlerts = arrayOfAlerts.map((alert: Alert): JSX.Element => {
      if (moment(alert.sent).format('DD-MM-YY') !== prevSent) {
        showSeparator = true;
        prevSent = moment(alert.sent).format('DD-MM-YY');
      } else if (arrayOfAlerts[0] === alert) {
        showSeparator = true;
      } else {
        showSeparator = false;
      }
      return (
        <div key={`${alert._id}outerDiv`}>
          {showSeparator ? <DateSeparator date={alert.sent} /> : null}
          <AlertCard
            key={alert._id}
            alert={alert}
            updateAlertDetailsDisplay={updateAlertDetailsDisplay}
            renderChannels={renderChannels}
          />
        </div>
      );
    });
    return <div id="alertContainer" className="alertContainer">{allAlerts}</div>;
  };

  const renderStatsSection = (arrayOfAlerts: Alert[]): JSX.Element => (
    <div className="statSection">
      <Analytics
        totalSent={arrayOfAlerts.reduce((sum, currentAlert) => sum + currentAlert.recipients.length, 0)}
        totalAcknowledged={arrayOfAlerts.reduce((sum, currentAlert) => sum + currentAlert.acknowledged.length, 0)}
        totalReceived={arrayOfAlerts.reduce((sum, currentAlert) => sum + currentAlert.devicesReceived.length, 0)}
      />
    </div>
  );

  // Use effects
  useEffect(() => {
    dashboardLogic.getNextPageOfAlerts({
      dispatch,
      setErrorMessage,
      setSuccessfulStates,
      current,
    });
    if (devices.length === 0) fetchRegisteredDevices(dispatch);
  }, []);

  /*
  useEffect(() => {
    channelLogic.getChannels(dispatch);
  }, []);
   */

  useEffect(() => {
    // TODO update with new user id
    const userID = sessionStorage.getItem('authorizedUser');
    if ((userID !== null && userID !== undefined) && passModalCalled === false) {
      setPassModalCalled(true);
      setAdminId(userID);
      usersLogic.checkAdminPassword(userID, setShowPassModal);
    }
  });

  return (
    <div className="dashboardContainer">
      <main className="outerFlexContainer">
        <section className="alertSection">
          <header
            className="sectionHeading dashboardHeading"
            aria-label={isToggled === true ? 'Recent Alerts Page' : 'Analytics Page'}
          >
            {isToggled === true
              ? (
                <h1
                  id="recentAlertsHeading"
                  tabIndex={0}
                >
                  Recent Alerts
                </h1>
              )
              : (
                <h1
                  id="analyticsHeading"
                  tabIndex={0}
                >
                  Analytics
                </h1>
              )}
            {alertDetail !== undefined ? (
              <Button
                className="primary-button returnButton"
                text="Return to Alerts"
                onClick={() => updateAlertDetailsDisplay('')}
              />
            ) : (
              <ToggleButton
                showAlerts={buttonToggled}
                showAnalytics={buttonToggled}
                click={buttonToggled}
                selected={isToggled}
              />
            )}
          </header>
          {/* filter container */}
          {showingByDate !== true ? (
            <form>
              <div className={dropdownOption === 'Filter By: text' ? 'input-group mb-3' : 'input-group mb-3 search-filter'}>
                <div className="input-group-prepend">
                  <Dropdown toggle={dropdownToggle} isOpen={(dropdownOpen)}>
                    <button
                      className="btn primary-button dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {dropdownOption ? `${dropdownOption}` : 'Filter By:'}
                    </button>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setDropDownOption('Filter by: text');
                          dashboardLogic.getNextPageOfAlerts({
                            dispatch, setErrorMessage, setSuccessfulStates, current: 0,
                          });
                        }}
                      >
                        Reset Filter
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setDropDownOption('Filter by: channels');
                          setSearchFilter('');
                        }}
                      >
                        Channels
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setDropDownOption('Filter by: text');
                          setChannelPills([]);
                        }}
                      >
                        Text
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                {dropdownOption === 'Filter by: channels' ? (
                  <div className="input-group-prepend">
                    <ChannelSelectorComponent
                      channels={channels}
                      setChannelPills={setChannelPills}
                      channelPills={channelPills}
                      className="search-filter-selector"
                    />
                    <div className="form-control search-filter-pills">
                      {channelPills.map((channel: Channel) => (
                        <Pill
                          key={`${channel.name}-senderPill`}
                          content={channel.name}
                          backgroundColor={channel.style}
                          textColor={channel.color}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
                {dropdownOption === 'Filter by: text' ? (
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => { setSearchFilter(e.target.value); }}
                    value={searchFilter}
                    aria-label="This is a text filter input field. The 'search' button is disabled until something is typed in this field"
                  />
                ) : null}
                <div className="input-group-append">
                  <Button
                    type="submit"
                    text="Search"
                    disabled={!!(searchFilter === '' && !channelPills.length)}
                    ariaLabel="filter channels"
                    onClick={(e) => dashboardLogic.fetchQueriedAlerts(e, dispatch, current, channelPills, searchFilter, setErrorMessage)}
                  />
                </div>
              </div>
            </form>
          ) : null}
          {/* error container */}
          {isToggled === true && errorMessage.length ? (
            <div>
              <Status type="error" message={errorMessage} />
            </div>
          ) : isToggled === false && errorMessage.length ? (
            <div>
              <Status type="error" message={`${errorMessage} to show statistics`} />
            </div>
          ) : null}
          {/* warning container */}
          {isToggled === true && !alerts.length ? (
            <div>
              <Status type="warning" message="There are no alerts to display" />
            </div>
          ) : isToggled === false && !alerts.length ? (
            <div>
              <Status type="warning" message="No alerts available for which to show statistics" />
            </div>
          ) : null}
          {/* main container */}
          {alertDetail !== undefined && (alerts.length || dateAlerts.length) ? (
            <SingleAlertAnalytics
              alertDetail={alertDetail}
              renderChannels={renderChannels}
            />
          ) : (alerts.length && !showingByDate) ? (
            isToggled ? renderAlertSection(alerts) : renderStatsSection(alerts)
          ) : (dateAlerts.length && showingByDate) ? (
            isToggled ? renderAlertSection(dateAlerts) : renderStatsSection(dateAlerts)
          ) : null}
          {/* page nav container */}
          {showingByDate || filteredAlerts || alertDetail
            ? null : (
              <div className="alertSectionInfo">
                <p>
                  {current === 1 ? current : (current * 10) - 10}
                  {' '}
                  -
                  {' '}
                  {(current * 10) < totalDocs ? (current * 10) : totalDocs}
                  {' '}
                  of
                  {' '}
                  {totalDocs}
                </p>
                <div className="alertNavButtons">
                  <Button
                    className="primary-button prevPageButton"
                    text={(<FontAwesomeIcon icon={faArrowLeft} />)}
                    disabled={!hasPrevious}
                    onClick={() => dashboardLogic.getPrevPageOfAlerts({
                      dispatch, setErrorMessage, setSuccessfulStates, current,
                    })}
                    ariaLabel="previous page"
                  />
                  <Button
                    className="primary-button nextPageButton"
                    text={(<FontAwesomeIcon icon={faArrowRight} />)}
                    disabled={!hasNextPage}
                    onClick={() => dashboardLogic.getNextPageOfAlerts({
                      dispatch, setErrorMessage, setSuccessfulStates, current,
                    })}
                    ariaLabel="next page"
                  />
                </div>
              </div>
            )}
        </section>
        <section className="rightSection">
          <div className="calendarSection">
            <Calendar
              setAlerts={setDateAlerts}
              showByDate={setShowingByDate}
              setErrorMessage={setErrorMessage}
            />
          </div>
          <div
            className="deviceStatsContainer"
            tabIndex={0}
          >
            <div className="card statCard">
              <p className="activeUserNumber">
                {activeUsers}
              </p>
              <p
                className="activeUserTitle"
                aria-label="Number of Active Users"
              >
                Active Users
              </p>
            </div>
            <div
              className="card statCard"
              tabIndex={0}
            >
              <p className="registeredDevicesNumber">
                {devices.length}
              </p>
              <p
                className="registeredDevicesTitle"
                aria-label="Number of Registered Devices"
              >
                Registered Devices
              </p>
            </div>
          </div>
        </section>
        <section>
          {showPassModal && (
          <PassChangeModal
            id={adminId}
            toggleModal={togglePassModal}
            open={showPassModal}
          />
          )}
        </section>
      </main>
    </div>
  );
};

export default DashboardContainer;
