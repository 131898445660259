import {
  LatLng, LatLngBounds, LatLngTuple, Point,
} from 'leaflet';
import { Devices } from '../../Typings/deviceTypes';
import senderUtils from '../../Utils/senderUtils';

export interface SenderState {
  devices: Devices[];
  selectedMapDevices: Devices[];
  bounds: LatLngBounds | null;
  centerPnt: Point | null;
  radius: number;
  reRenderMap: boolean;
  individualSelectedDevices: string[];
  shapes: { lastCenter: LatLng, associatedDevices?: Devices[] }[];
  mostRecentShape: LatLng;
  polyPoints: LatLng[]
  geoEnabled: boolean | undefined;
  mapCenter: LatLngTuple
  mapZoom: number
}

const initState: SenderState = {
  devices: [],
  selectedMapDevices: [],
  bounds: null,
  centerPnt: null,
  radius: 0,
  reRenderMap: false,
  individualSelectedDevices: [],
  shapes: [],
  mostRecentShape: {} as LatLng,
  polyPoints: [],
  geoEnabled: undefined,
  mapCenter: [37.271, -79.9414],
  mapZoom: 5,
};

export const handleDeviceUpdates = (state: SenderState, deviceUpdates: Devices): SenderState => {
  const newDevices = [...state.devices.filter((device) => device._id !== deviceUpdates._id), deviceUpdates];
  let newSelected: Devices[] = state.selectedMapDevices;
  if (state.bounds !== null) {
    newSelected = senderUtils.findAndSelectDevices(newDevices, state.bounds, null, null, [], state.selectedMapDevices) as Devices[];
  }
  if (state.centerPnt !== null && state.radius !== 0) {
    newSelected = senderUtils.findAndSelectDevices(newDevices, null, state.centerPnt, state.radius, [], state.selectedMapDevices) as Devices[];
  }
  return { ...state, devices: newDevices, selectedMapDevices: newSelected };
};

const senderReducer = (
  state = initState,
    action: { type: string; payload:  number | Devices[] | boolean | any },//eslint-disable-line
): SenderState => {
  switch (action.type) {
    case 'SET_REGISTERED_DEVICES':
      return {
        ...state,
        devices: action.payload as Devices[],
      };
    case 'ADD_REGISTERED_DEVICES':
        return {
            ...state,
            devices: [...state.devices, ...action.payload as Devices[]],
        }
    case 'UPDATE_REGISTERED_DEVICES':
      const updatedDevices = state.devices.filter(
        (device) => !action.payload.some((updatedDevice) => updatedDevice._id === device._id)
      );
      return {
        ...state,
        devices: [...updatedDevices, ...action.payload],
      };
    case 'DELETE_REGISTERED_DEVICES':
      return {
        ...state,
        devices: state.devices.filter(
          (device) => !action.payload.some((removedDevice) => removedDevice._id === device._id)
        ),
      };
    case 'SELECTED_DEVICES':
      return {
        ...state,
        selectedMapDevices: action.payload as Devices[],
      };
    case 'INDIVIDUAL_DEVICES':
      return {
        ...state,
        individualSelectedDevices: action.payload as string[],
      };
    case 'UPDATED_DEVICE':
      return handleDeviceUpdates(state, action.payload as unknown as Devices);
    case 'SET_GEO_MAP':
      return {
        ...state,
        bounds: action.payload.bounds as unknown as LatLngBounds,
        radius: action.payload.radius as unknown as number,
        centerPnt: action.payload.centerPnt as unknown as Point,
        polyPoints: action.payload.polyPoints as LatLng[],
      };
    case 'MAP_CENTER':
      return {
        ...state,
        mapCenter: action.payload as LatLngTuple,
      };
    case 'MAP_ZOOM':
      return {
        ...state,
        mapZoom: action.payload,
      };
    case 'RESET_MAP_RENDER':
      return {
        ...state,
                reRenderMap: action.payload, // eslint-disable-line
      };
    case 'ADD_SHAPE':
      return {
        ...state,
        shapes: [...state.shapes, { lastCenter: action.payload as unknown as LatLng }],
      };
    case 'MOST_RECENT_SHAPE':
      return {
        ...state,
        mostRecentShape: action.payload as LatLng,
      };
    case 'REMOVE_SHAPE':
      return {
        ...state,
        shapes: action.payload as unknown as [],
      };
    case 'ADD_SHAPE_DEVICES':
      return {
        ...state,
        shapes: [...state.shapes.filter((lc) => lc.lastCenter !== action.payload.lastCenter), action.payload] as unknown as [],
      };
    case 'GEO_ENABLED':
      return {
        ...state,
        geoEnabled: action.payload,
      };
    case 'DEVICES_DELETED':
      return {
        ...state,
        devices: [...state.devices.filter((device) => device._id !== action.payload._id)],
        selectedMapDevices: [...state.selectedMapDevices.filter((device) => device._id !== action.payload._id)],
      };
    default:
      return state;
  }
};

export default senderReducer;
