import React, { useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile } from '@fortawesome/free-solid-svg-icons';
import { LatLngBounds, Point } from 'leaflet';
import { RootState } from '../../Redux/reducers';
import Button from '../../Components/Button';
import ChannelSelectorComponent from '../../Components/ChannelSelector';
import { Channel } from '../../Typings/channelTypes';
import Pill, { GeoPill } from '../../Components/Pill';
import channelLogic from '../Channels/channelLogic';
import { ToastType } from '../../Typings/toastTypes';
import type SocketController from '../../Utils/SocketController';
import SenderMap from './SenderMap';
import { fetchRegisteredDevices } from '../../Utils/deviceUtils';
import DeviceList from './DeviceList';
import { Devices } from '../../Typings/deviceTypes';
import DatabaseHandler from "../../Firebase/databaseHandler";

const senderUrl = '/api/v2/sender/sender/';

export const submitAlert = async (
  channels: Channel[],
  messageText: string,
  selectedMapDevices: Devices[],
  individualSelectedDevices: string[],
  radius: number,
  centerPnt: Point | null,
  bounds: LatLngBounds | null,
  setChannelPills: React.Dispatch<React.SetStateAction<Channel[]>>,
  setMessageText: (message: string) => void,
  dispatch: (action: { type: string; payload: ToastType | number | boolean | { bounds: LatLngBounds | null, radius: number,
    centerPnt: Point | null } | Devices[] }) => void,
  //socket: SocketController,
): Promise<boolean> => {
  let payload: any = {};//eslint-disable-line
  const channelIds = channels.map((channel: Channel) => {
    const channelId = channel._id;
    return channelId;
  });
  if (selectedMapDevices.length || centerPnt !== null) {
    const deviceIds = selectedMapDevices.map((device) => device._id);
    payload = { channels: channelIds, message: messageText, geo: { message: messageText, deviceIds } };
    if (individualSelectedDevices.length) {
      payload.geo.deviceIds = individualSelectedDevices;
    } else if (deviceIds.length) {
      payload.geo.deviceIds = deviceIds;
    }
    if (radius && centerPnt !== null) {
      payload.geo.center = centerPnt;
      payload.geo.radius = radius;
    } else if (bounds !== null) {
      payload.geo.bounds = bounds;
    }
  } else {
    payload = { channels: channelIds, message: messageText };
  }
  console.log('SENDER PAYLOAD: ', payload);
  setMessageText('');
  setChannelPills([]);
  await DatabaseHandler.sendAlert(payload);
  /*
  const token = sessionStorage.getItem('authToken');
  const config = {
    headers: { Authorization: `Bearer ${token !== null ? token : ''}` },
  };
  try {
    const res = await axios.post(senderUrl, payload, config);
    const errMessage = res.status === 401 ? 'Unauthorized' : 'Error sending alert';
    if (res.status === 201) {
      //socket.emitNewAlert(res.data);
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          toastType: 'success',
          heading: 'Sender',
          message: 'Alert was successfully sent',
          autoDismiss: true,
        },
      });
      setMessageText('');
      setChannelPills([]);
      dispatch({ type: 'RESET_MAP_RENDER', payload: true });
      dispatch({ type: 'INDIVIDUAL_DEVICES', payload: [] });
      dispatch({ type: 'REMOVE_SHAPE', payload: [] });
      dispatch({ type: 'MOST_RECENT_SHAPE', payload: [] });
      return true;
    }
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Sender',
        message: `${errMessage}`,
        autoDismiss: true,
      },
    });
    return false;
  } catch (e) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Sender',
        message: 'Error sending alert',
        autoDismiss: true,
      },
    });
    return false;
  }

   */
};

export const messageMissing: (
  messageText: string,
  channelPills: Channel[],
  selectedMapDevices: Devices[],
) => boolean = (messageText, channelPills, selectedMapDevices) => {
  if (messageText.length > 0 && channelPills.length > 0) {
    return false;
  } if (messageText.length > 0 && selectedMapDevices.length > 0) {
    return false;
  }
  return true;
};

const SenderContainer = (): JSX.Element => {
  // TODO: display status messages when there is a value in the message state.
  // TODO: Save message object to sessionStorage to repopulate when navigate back to sender.
  const dispatch = useDispatch();
  const [channelPills, setChannelPills] = React.useState<Channel[]>([]);
  const [messageText, setMessageText] = React.useState('');
  const { channels } = useSelector((state: RootState) => state.channel);
  const {
    devices, selectedMapDevices, radius, centerPnt, bounds, individualSelectedDevices, geoEnabled,
    mapCenter,
  } = useSelector((state: RootState) => state.sender);
  console.log('CHANNELS', channels);
  const { senderVisited } = useSelector((state: RootState) => state.dashboard);
  //const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  /*
  React.useEffect(() => {
    channelLogic.getChannels(dispatch);
    if (!senderVisited || !devices.length) {
      fetchRegisteredDevices(dispatch);
      dispatch({ type: 'VISIT_SENDER', payload: true });
    }
  }, []);
   */

  const showNumRecipientDevices = () => {
    if (selectedMapDevices.length && !individualSelectedDevices.length) {
      if (selectedMapDevices.length === 1) {
        return `${selectedMapDevices.length} Device`;
      }
      return `${selectedMapDevices.length} Devices`;
    }
    if (selectedMapDevices.length && individualSelectedDevices.length) {
      if (individualSelectedDevices.length === 1) {
        return `${individualSelectedDevices.length} Device`;
      }
      return `${individualSelectedDevices.length} Devices`;
    }
    return true;
  };

  const renderPills = () => (
    <>
      <div className="recipientLabel">Recipients:</div>
      {selectedMapDevices.length
        ? (<GeoPill />) : null}
      {channelPills.map((channel: Channel) => (
        <Pill
          key={`${channel.name}-senderPill`}
          content={channel.name}
          backgroundColor={channel.style}
          textColor={channel.color}
        />
      ))}
      {showNumRecipientDevices()}
    </>
  );

  const showNumSelectedDevices = () => {
    if (selectedMapDevices.length && !individualSelectedDevices.length) {
      if (selectedMapDevices.length === 1) {
        return `${selectedMapDevices.length} Selected Device`;
      }
      return `${selectedMapDevices.length} Selected Devices`;
    }
    if (selectedMapDevices.length && individualSelectedDevices.length) {
      if (individualSelectedDevices.length === 1) {
        return `${individualSelectedDevices.length} Selected Device`;
      }
      return `${individualSelectedDevices.length} Selected Devices`;
    }
    return true;
  };


  const renderMap = () => {
    const permissions = sessionStorage.getItem('permissions');
    const parsedPerm: string = permissions && JSON.parse(permissions);//eslint-disable-line
    if (geoEnabled === true
      && parsedPerm?.indexOf('geo_send_alert') !== -1
      && parsedPerm.indexOf('geo_fetch_devices') !== -1

    ) {
      return (
        <SenderMap
          incomingDevices={devices}
          userLocation={mapCenter as number[]}
          channelPills={channelPills}
          persistMap
        />
      );
    }
    return null;
  };

  return (
    <main className="outerContainer">
      {mapCenter && renderMap()}
      <hr />
      <div className="senderBody">
        <section className="senderLeft">
          <header
            aria-label="Send Alerts Page"
            tabIndex={0} // eslint-disable-line
            className="sectionHeading"
          >
            <h1>Send Alerts</h1>
          </header>
          <ChannelSelectorComponent
            channels={channels}
            setChannelPills={setChannelPills}
            channelPills={channelPills}
            sender
          />
          {selectedMapDevices.length !== 0 && (
          <div className="numDevicesSelected">
            <FontAwesomeIcon icon={faMobile} />
            {' '}
            {showNumSelectedDevices()}
          </div>
          )}
          {selectedMapDevices.length !== 0 && <DeviceList />}
        </section>

        <div className="card senderContainer">
          <div className="pillsContainer">
            {renderPills()}
          </div>
          <textarea
            name="message"
            form="senderForm"
            className="senderText"
            rows={5}
            placeholder="Message text..."
            value={messageText}
            maxLength={1800}
            onChange={(e) => setMessageText(e.target.value)}
            aria-label="This is the message text area on the send alerts page. The send button is disabled until a message is typed."
          />
          <Button
            text="SEND"
            onClick={() => submitAlert(
              channelPills,
              messageText,
              selectedMapDevices,
              individualSelectedDevices,
              radius,
              centerPnt,
              bounds,
              setChannelPills,
              setMessageText,
              dispatch,
            )}
            className="senderButton primary-button"
            type="submit"
            disabled={messageMissing(messageText, channelPills, selectedMapDevices)}
            aria-label="send message button"
            aria-disabled="true"
          />
        </div>
      </div>
    </main>
  );
};

export default SenderContainer;
