import React, { useState } from 'react';

export type Tab = {
  label: string,
  content: JSX.Element,
};

interface TabsContainerInterface {
  tabs: Tab[],
}

export const TabsContainer = (props: TabsContainerInterface): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const { tabs } = props;

  return (
    <>
      <ul className="nav nav-tabs">
        {
          tabs.map((tab, index) => (
            <li className="nav-item">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(index);
                }}
                className={activeTab === index ? 'nav-link active' : 'nav-link'}
                key={tab.label}
              >
                {tab.label}
              </button>
            </li>
          ))
        }
      </ul>
      {tabs[activeTab].content}
    </>
  );
};
